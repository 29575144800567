import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const InputHelperText = ({ error, children, ...props }) => (
  <Box minHeight={24} {...props}>
    <Typography variant="caption" color="error">
      {error ? children : ' '}
    </Typography>
  </Box>
);

InputHelperText.propTypes = {
  error: PropTypes.bool,
  children: PropTypes.node,
};

InputHelperText.defaultProps = {
  error: false,
  children: null,
};

export default InputHelperText;
