import { useIsMobile } from '@fingo/lib/hooks';

const useWidth = () => {
  const isMobile = useIsMobile();
  const rateWidth = isMobile ? 53 : 60;
  const amountWidth = isMobile ? 90 : 120;
  const dateInputWidth = isMobile ? 93 : 175;
  const statusWidth = isMobile ? 120 : 200;
  const orderingOperationStatus = isMobile ? 120 : 315;
  const cessionStatusWidth = isMobile ? 120 : 360;
  const dateWidth = 105;
  const rutWidth = 110;
  const iconSize = 70;
  const buttonWidth = 90;
  const orderNumberWidth = isMobile ? 90 : 110;
  const littleMessageStatus = 120;
  return {
    rateWidth,
    amountWidth,
    dateInputWidth,
    dateWidth,
    rutWidth,
    iconSize,
    statusWidth,
    orderingOperationStatus,
    cessionStatusWidth,
    buttonWidth,
    orderNumberWidth,
    littleMessageStatus,
  };
};

export default useWidth;
