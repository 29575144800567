import React from 'react';
import { Menu, List, Box, Tabs, Tab, Link, Grid } from '@mui/material';
import PropTypes from 'prop-types';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function DoubleMenu({
  rightId,
  rightTitle,
  rightContent,
  leftId,
  leftTitle,
  leftContent,
  linkAction,
  linkText,
  link2Text,
  link2Action,
  ...props }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Menu
      {...props}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} style={{ alignSelf: 'center' }} variant="fullWidth">
            <Tab label={leftTitle} {...a11yProps(0)} id={leftId} />
            <Tab label={rightTitle} {...a11yProps(1)} id={rightId} />
          </Tabs>
        </Box>
        <Grid container>
          {linkAction && (
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <Link component="button" onClick={() => linkAction(value)}>{linkText}</Link>
            </Grid>
          )}
        </Grid>
        <TabPanel value={value} index={0}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {leftContent}
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {rightContent}
          </List>
        </TabPanel>
        <Grid container justifyContent="flex-end">
          {link2Action && (
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <Link component="button" onClick={link2Action}>{link2Text}</Link>
            </Grid>
          )}
        </Grid>
      </Box>
    </Menu>
  );
}
TabPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
DoubleMenu.propTypes = {
  rightId: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
  linkAction: PropTypes.func,
  linkText: PropTypes.string,
  link2Action: PropTypes.func,
  link2Text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  rightContent: PropTypes.array.isRequired,
  leftId: PropTypes.string.isRequired,
  leftTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  leftContent: PropTypes.array.isRequired,
};
DoubleMenu.defaultProps = {
  linkAction: null,
  linkText: null,
  link2Action: null,
  link2Text: null,
};
