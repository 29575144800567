import { CircularProgress, Box } from '@mui/material';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import PropTypes from 'prop-types';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const LazyLoadImage = ({ src, alt, placeholder, height, width }) => {
  if (!src) {
    return (
      <Box
        sx={{
          width,
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f0f0f0',
        }}
      >
        <QuestionMarkIcon color="#FFFFFF" fontSize="10px" />
      </Box>
    );
  }

  return (
    <ProgressiveImage src={src} placeholder={placeholder}>
      {(newSrc, loading) => (
        <Box
          sx={{
            width,
            height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {loading ? (
            <CircularProgress color="gray" />
          ) : (
            <img
              src={newSrc}
              alt={alt}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          )}
        </Box>
      )}
    </ProgressiveImage>
  );
};

LazyLoadImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  src: null,
  alt: '',
  placeholder: '',
};

export default LazyLoadImage;
