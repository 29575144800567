import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

const NewProductBanner = ({ color, textColor, availableUrls, children }) => {
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();

  const validUrl = availableUrls.includes(location?.pathname);
  return (
    showBanner && validUrl
    && (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: color,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      marginY="auto"
    >
      {children}
      <Button
        id="close-banner"
        sx={{
          padding: 0,
          minWidth: 'auto',
          height: 'auto',
          position: 'relative',
          paddingRight: 3,
        }}
        onClick={() => setShowBanner(false)}
      >
        <CloseIcon sx={{ color: textColor }} />
      </Button>
    </Box>

    )
  );
};

NewProductBanner.propTypes = {
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  availableUrls: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

NewProductBanner.defaultProps = {
  availableUrls: [],
};

export default NewProductBanner;
