import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validationTypes } from '@fingo/lib/helpers';
import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

function ValidationTextFieldDataGrid(props) {
  const { id, value, field, validationtype, ...other } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  const [errorMessage, setErrorMessage] = useState('');
  const handleOnBlur = () => {
    const { truthFunction, message } = validationTypes(value)[validationtype];
    if (!truthFunction() && value) {
      setErrorMessage(message);
    }
  };
  useEffect(() => {
    const { truthFunction } = validationTypes(value)[validationtype];
    if (truthFunction() || !value) {
      setErrorMessage('');
    }
  }, [value, validationtype]);

  return (
    <TextField
      variant="standard"
      onChange={handleValueChange}
      onBlur={handleOnBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      type={validationtype === 'phone' ? 'number' : 'text'}
      {...other}
    />
  );
}
export default ValidationTextFieldDataGrid;

ValidationTextFieldDataGrid.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  field: PropTypes.string.isRequired,
  validationtype: PropTypes.string,
};

ValidationTextFieldDataGrid.defaultProps = {
  validationtype: 'email',
  value: undefined,
};
