import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AvailableHeightBox } from '@fingo/lib/components/boxes';
import Header from './Header';

const Layout = ({ children }) => {
  const location = useLocation();
  const whiteBGC = ['/', '/register', '/login', '/provider-login', '/external-login', '/password-recovery', '/simulator', '/simulator-register'];
  const backgroundColor = useMemo(() => {
    if (whiteBGC.includes(location.pathname)) {
      return 'fingoWhite.main';
    }
    return 'background.dark';
  }, [location.pathname.split('/')[1]]);
  if (location.pathname === '/debtor-payment-checker') return <div>{children}</div>;
  return useMemo(() => (
    <>
      <Header />
      <AvailableHeightBox bgcolor={backgroundColor}>
        {children}
      </AvailableHeightBox>
    </>
  ), [children, backgroundColor]);
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
