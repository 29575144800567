import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import LazyLoadImage from '../image/LazyLoadImage';

const ImageInput = ({ src, alt, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      ...props.InputProps,
      sx: { borderRadius: 2 },
      startAdornment: props.inputProps.value ? (
        <Box display="flex" alignItems="center">
          <LazyLoadImage src={src} alt={alt} width={60} height={24} />
        </Box>
      ) : null,
    }}
  />
);

ImageInput.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  value: PropTypes.string,
  inputProps: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  InputProps: PropTypes.shape({
    className: PropTypes.string,
  }).isRequired,
};

ImageInput.defaultProps = {
  src: null,
  alt: '',
  value: null,
};

export default ImageInput;
