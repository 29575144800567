import React from 'react';
import PropTypes from 'prop-types';
import { TourGuide } from '@fingo/lib/components/joyRides';

const SophiaTourGuide = ({ run, setRun }) => {
  const steps = [
    {
      title: '¡Hemos agregado una nueva función para tu empresa!',
      content: 'Ahora podrás ver tus resultados del mes de manera mucho más eficiente.',
      target: '#name-container',
    },
    {
      title: 'Ventas',
      content: '¡Descubre cuanto fue lo que vendiste este ultimo mes y compáralos con tus resultados del período anterior!',
      target: '#sales-numbers',
    },
    {
      title: 'Cuentas por cobrar',
      content: 'Mantente informado del monto de tus cuentas por cobrar y pagar. Estas se deciden según la fecha de vencimiento de tus facturas. ¡Entréganos las credenciales de tu facturador para tener este valor mucho más exacto!',
      target: '#accounts-receivable',
    },
    {
      title: 'Gráficos',
      content: 'Aquí se muestra tu evolución en las compras y ventas comparado con el período anterior. Ve el desempeño de tu empresa este período.',
      target: '#graphs',
    },
  ];
  return (
    <TourGuide
      run={run}
      setRun={setRun}
      steps={steps}
    />
  );
};

SophiaTourGuide.propTypes = {
  run: PropTypes.bool.isRequired,
  setRun: PropTypes.func.isRequired,
};

export default SophiaTourGuide;
