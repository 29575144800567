import React from 'react';
import PropTypes from 'prop-types';
import { TourGuide } from '@fingo/lib/components/joyRides';

const MySalesTourGuide = ({ run, setRun }) => {
  const steps = [
    {
      title: 'Cuentas por cobrar',
      content: 'En esta tabla visualizas todos los documentos que tienes por cobrar.',
      target: '#datagrid-box',
    },
    {
      title: 'Filtros',
      content: 'Filtra tus documentos por los distintos estados que tienen tus documentos.',
      target: '#open-filters',
    },
    {
      title: 'Fechas',
      content: 'Busca tus documentos de acuerdo a las fechas de emisión.',
      target: '#filter-dates',
    },
  ];
  return (
    <TourGuide
      run={run}
      setRun={setRun}
      steps={steps}
    />
  );
};

MySalesTourGuide.propTypes = {
  run: PropTypes.bool.isRequired,
  setRun: PropTypes.func.isRequired,
};

export default MySalesTourGuide;
