import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';

const BulletListItem = ({ children, color, ...props }) => (
  <ListItem
    {...props}
    sx={{
      '&:before': {
        content: '"•"',
        color,
        fontWeight: 'bold',
        display: 'inline-block',
        width: '10px',
      },
      // eslint-disable-next-line react/prop-types
      ...props.sx,
    }}
  >
    {children}
  </ListItem>
);

BulletListItem.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

BulletListItem.defaultProps = {
  color: 'primary.main',
};

export default BulletListItem;
