import React from 'react';
import { useQuery } from '@apollo/client';
import { FingoNavbar } from '@fingo/lib/components/layout';
import CountryNavItem from '@fingo/lib/components/layout/navbar/CountryNavItem';
import CurrencyNavItem from '@fingo/lib/components/layout/navbar/CurrencyNavItem';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import { useDynamicFingoLogo, useIsLogged } from '@fingo/lib/hooks';
import { GET_USER } from '@fingo/lib/graphql';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';
import KobrosNewProductBanner from './KobrosBanner';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const { data: userData } = useQuery(GET_USER);
  const fingoLogo = useDynamicFingoLogo();

  return (
    <>
      <FingoNavbar>
        <LogoNavItem logo={fingoLogo} isLogged={isLogged} id="nav-logo" />
        {isLogged && userData?.getUser && <LoggedInDesktopNavItems />}
        {!isLogged && <LoggedOutNavItems title="Comencemos" />}
        <CountryNavItem />
        <CurrencyNavItem />
      </FingoNavbar>
      {isLogged && <KobrosNewProductBanner />}
    </>
  );
};

export default DesktopNavbar;
