import { styled, TextField } from '@mui/material';

const UnderlineColorTextField = styled(
  TextField,
  { shouldForwardProp: (prop) => !['underlineColor'].includes(prop) },
)(({ underlineColor }) => ({
  '& .MuiInput-root': {
    '&::before, &::after, &:hover:before': {
      borderBottomColor: `${underlineColor} !important`,
    },
  },
}));

export default UnderlineColorTextField;
