import React, { useState, useCallback } from 'react';
import { Button, Menu, ListItem, ListItemText, Typography } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

const MenuFingoButton = ({ value, options, setValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const close = useCallback(() => setAnchorEl(null), []);
  const open = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const select = useCallback((newValue) => {
    setValue(newValue);
    setAnchorEl(null);
  }, [setValue]);
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        endIcon={<ArrowDropDown />}
        aria-controls={value}
        onClick={open}
        fullWidth
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography component="span" variant="body1">
          {options.find((option) => option.value === value).name}
        </Typography>
      </Button>
      <Menu
        id={String(value)}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map((option) => (
          <ListItem key={String(option.value)} button onClick={() => select(option.value)}>
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </Menu>
    </>
  );
};

MenuFingoButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  })).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default MenuFingoButton;
